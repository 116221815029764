
import { Parser, Player, DB } from "svga";
import { defineComponent, ref } from "vue";
import $ from "jquery";

export default defineComponent({
  name: "SuperBarrage",
  setup() {
    const num = ref(0);
    const appendBody = ref("");
    const superBarrageList: any = ref([]);
    const db = new DB();

    const superSvgaAnimation = async (data: any) => {
      data = JSON.parse(data);
      num.value++;
      superBarrageList.value.push(data.unionkey);

      if (superBarrageList.value.length >= 20) {
        $(`.${superBarrageList.value[0]}`).remove();
        superBarrageList.value.shift();
      }

      if (num.value === 1) {
        appendBody.value = "#superItemTop";
      } else if (num.value === 2) {
        appendBody.value = "#superItemCenter";
      } else if (num.value === 3) {
        appendBody.value = "#superItemBottom";
        num.value = 0;
      }

      const messageStr = JSON.parse(data.messageContent.messageStr);
      const { sendUserHeadImgUrl, sendUserName } = data;

      let svga: any = await db.find(messageStr.svga);

      if (!svga) {
        const parser = new Parser({ isDisableImageBitmapShim: true });
        svga = await parser.load(messageStr.svga);
        await db.insert(messageStr.svga, svga);
      }

      const innerHTML: any = $(`<canvas id="${data.unionkey}"></canvas>`);

      const css: any = {
        kaixin: { width: "470px", height: "160px" },
        shili: { width: "700px", height: "250px" },
        zhenai: { width: "740px", height: "370px" }
      };

      $(`<div class="superItemContent superItemContent${messageStr.super_code} ${data.unionkey}">
          <div class="content">
            <div class="head" style="background-image: url('${sendUserHeadImgUrl}')"></div>
            <div class="text">${sendUserName}：${messageStr.content}</div>
          </div>
        </div>`).appendTo($(appendBody.value));

      innerHTML.css({
        position: "absolute",
        right: "0",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: "9",
        width: css[messageStr.super_code].width,
        height: css[messageStr.super_code].height
      });

      innerHTML.appendTo($(`.${data.unionkey}`));

      $(`.${data.unionkey}`).addClass("animate__Barrage__join");

      const player = new Player({
        container: document.getElementById(data.unionkey) as HTMLCanvasElement,
        loop: 0,
        isOpenNoExecutionDelay: true
      });

      await player.mount(svga);

      player.onEnd = () => {};

      //开始播放动画
      player.start();
    };

    return {
      superSvgaAnimation
    };
  }
});
