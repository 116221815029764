
import { defineComponent, ref, onMounted } from "vue";
import { getPrizesWinner } from "@/api/api"; // 接口
import $ from "jquery";
import Prize from "@/views/Prize.vue";
export default defineComponent({
  name: "lottery",
  components: {
    Prize
  },
  props: {
    hallCode: {
      type: String,
      default: ""
    },
    hallId: {
      type: String,
      default: ""
    },
    showPrize: {
      type: Boolean,
      default: false
    },
    prizeType: {
      type: String,
      default: ""
    },
    prizeInfo: {
      type: Object,
      defalute: {}
    }
  },
  setup(props, context) {
    const allList = ref<any>([]);
    const list = ref<any>([]);
    const num = ref(0);
    const winList = ref<any>([]);
    const resultList = ref<any>([]);
    const resultList2 = ref<any>([]);
    const interval = ref<any>(null);

    const fromData: any = () => {
      interval.value = setInterval(() => {
        num.value++;

        if (num.value > 22) {
          num.value = 1;
        }

        const item = allList.value.shift();
        item.index = num.value;

        list.value.push(item);
        allList.value.push(item);

        if (list.value.length >= 30) {
          $(`.lottery-scroll`)
            .children()
            .first()
            .remove();
        }
      }, 200);
    };

    const init = () => {
      allList.value.splice(0);
      for (let i = 1; i <= 18; i++) {
        allList.value.push({
          imageUrl: `https://fdshare.oss-cn-shenzhen.aliyuncs.com/meet/pc/lottery/head/${i}.png`,
          nickName: ""
        });
      }
    };

    const _getPrizesWinner: any = async (prizeId: any, temeType: any) => {
      resultList.value.splice(0);
      resultList2.value.splice(0);
      await getPrizesWinner({
        prizeId: prizeId
      }).then((res: any) => {
        const { code, result } = res.data;
        if (code === 101) {
          clearInterval(interval.value);
          result.forEach((item: any, index: any) => {
            num.value++;

            if (num.value > 22) {
              num.value = 1;
            }

            item.index = num.value;
            winList.value.push(item);
          });

          if (temeType === "fiveBlessings") {
            resultList2.value = JSON.parse(JSON.stringify(winList.value));
          } else {
            resultList.value = JSON.parse(JSON.stringify(winList.value));
          }

          // setTimeout(() => {
          allList.value.splice(0);
          list.value.splice(0);
          winList.value.splice(0);
          context.emit("update:prizeType", "2");
          // }, 5000);
        }
      });
    };

    return {
      list,
      winList,
      resultList,
      resultList2,
      init,
      fromData,
      _getPrizesWinner
    };
  }
});
