
import {
  defineComponent,
  ref,
  watch,
  onMounted,
  onUnmounted,
  provide
} from "vue";
import reconnectingWebsocket from "reconnecting-websocket";
import Programme from "@/views/Programme.vue"; // 节目单
// import Barrage from "@/views/Barrage.vue"; // 弹幕
// import CycleBarrage from "@/views/CycleBarrage.vue"; // 弹幕
import CrossCycleBarrage from "@/views/CrossCycleBarrage.vue"; // 弹幕
import Prize from "@/views/Prize.vue"; // 弹幕
import {
  initScreen,
  getPrizesWinner,
  getNewsRecordAndGiftList,
  getEffectGiftList
} from "@/api/api"; // 接口
// import SVGA from "svgaplayerweb";
import { Parser, Player, DB } from "svga";

import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay } from "swiper";
import { useRoute, useRouter } from "vue-router";
import { initDB } from "@/utils";
import SuperBarrage from "@/views/SuperBarrage.vue";
import lottery from "@/views/lottery.vue";
SwiperCore.use([Autoplay]);

export default defineComponent({
  name: "Main",
  components: {
    Programme,
    // Barrage,
    // CycleBarrage,
    CrossCycleBarrage,
    Prize,
    Swiper,
    SwiperSlide,
    SuperBarrage,
    lottery
  },
  setup() {
    const { query } = useRoute();
    const qrCodePostion = ref();
    const qrCodedisX = ref();
    const qrCodedisY = ref();
    const hallCode = ref();
    const Programme = ref();
    const Barrage = ref();
    const showBarrage: any = ref(true);
    const showProgramme: any = ref(false);
    const showPrize: any = ref(false);
    const showQRCode: any = ref(true);
    const showGift: any = ref(false);
    // const qrCodeUrl: any = ref(
    //   "https://fdshare.oss-cn-shenzhen.aliyuncs.com/meet/game/dp7.png"
    // );
    const qrCodeUrl: any = ref();
    const programmeList: any = ref([]);
    const giftGiver: any = ref({
      avatar: "",
      name: "",
      type: ""
    });

    const params = useQueryParams(); // 浏览器参数
    const stringList = ref([]);
    const mp4Url = ref("");

    const superBarrage = ref();

    const _getNewsRecordAndGiftList = async (pageNum: any) => {
      const formData = new FormData();
      formData.append("hallcode", hallCode.value);
      formData.append("pageNum", pageNum);
      formData.append("pageSize", "50");
      await getNewsRecordAndGiftList(formData).then((res: any) => {
        const { code, result } = res.data;
        if (code === 101) {
          result.records.forEach((item: any) => {
            item.text =
              decodeURIComponent(
                item.sendMsg.replace(/\[\[/g, "").replace(/\]\]/g, "")
              ) || `${item.nickName} 赠送了 ${item.giftName}`;
            item.avatar = item.imageUrl;
            item.giftIcon = item.giftImg;
            Barrage.value.append(item, true);
          });

          if (result.current < result.pages) {
            _getNewsRecordAndGiftList(result.current + 1);
          }
        }
      });
    };

    const { prizeType, prizeInfo, isDrawing, bgUrl, resultList } = useWebSocket(
      params.hallCode ? params.hallCode : query.hallCode,
      Programme,
      Barrage,
      showBarrage,
      showProgramme,
      showPrize,
      showGift,
      showQRCode,
      giftGiver,
      stringList,
      mp4Url,
      _getNewsRecordAndGiftList,
      superBarrage
    ); // WebSocket

    onMounted(() => {
      // provide('hallCode', params.hallCode)
      hallCode.value = params.hallCode ? params.hallCode : query.hallCode;
      // alert('document.body.clientHeight:'+document.body.clientHeight+'document.body.clientWidth:'+document.body.clientWidth)

      // 可以传一个不小于1的整数，理论上来说数字越大，结果越精确或者说越平均，但是等待时间也就越长
      // 实测50是最理想的，结果比较精确了，小于50结果偏差会比较大
      // 参数不传默认值就是50
      // 60帧的电脑屏幕测试结果在61和62之间浮动，120帧的手机测试结果在121和122之间浮动
      getScreenFps!().then(fps => {
        console.log("当前屏幕刷新率为", fps);
        giftGiver.value.name = "当前屏幕刷新率为" + fps;
      });

      initScreen({
        hallcode: params.hallCode ? params.hallCode : query.hallCode
      }).then((res: any) => {
        const { code, result } = res.data;
        if (code === 101) {
          const { hallScreen, programmeVOList } = result;
          showProgramme.value = hallScreen.programme === "0" ? false : true;
          showBarrage.value = hallScreen.barrage === "0" ? false : true;
          showQRCode.value = hallScreen.qrCode === "0" ? false : true;
          qrCodeUrl.value = hallScreen.qrCodeUrl;
          programmeList.value = programmeVOList;
          bgUrl.value = hallScreen.background;

          if (hallScreen.bdType === "1") {
            const dom: any = document.getElementById("main");
            dom.style.backgroundImage = `url(${hallScreen.background})`;
          } else if (hallScreen.bdType === "2") {
            stringList.value = hallScreen.stringList;
          } else if (hallScreen.bdType === "3") {
            mp4Url.value = hallScreen.mp4Url;
          }

          if (result.source === "xinnuo") {
            (function() {
              const link: any =
                document.querySelector("link[rel*='icon']") ||
                document.createElement("link");
              link.type = "image/x-icon";
              link.rel = "shortcut icon";
              link.href = "./favicon_xinnuo.ico";
              document.getElementsByTagName("head")[0].appendChild(link);
            })();
          }

          document.title = result.banquetName;
        }
      });

      _getNewsRecordAndGiftList(1);
    });

    /**
     * @param {number} targetCount 不小于1的整数，表示经过targetCount帧之后返回结果
     * @return {Promise<number>}
     */
    const getScreenFps = (() => {
      // 先做一下兼容性处理
      const nextFrame = [
        window.requestAnimationFrame,
        (window as any).webkitRequestAnimationFrame,
        (window as any).mozRequestAnimationFrame
      ].find(fn => fn);
      if (!nextFrame) {
        console.error("requestAnimationFrame is not supported!");
        return;
      }
      return (targetCount = 50) => {
        // 判断参数是否合规
        if (targetCount < 1)
          throw new Error("targetCount cannot be less than 1.");
        const beginDate = Date.now();
        let count = 0;
        return new Promise(resolve => {
          (function log() {
            nextFrame(() => {
              if (++count >= targetCount) {
                const diffDate = Date.now() - beginDate;
                const fps = (count / diffDate) * 1000;
                return resolve(fps);
              }
              log();
            });
          })();
        });
      };
    })();

    const qrCodeMove = (e: any) => {
      if (qrCodePostion.value) {
        console.log(e);
        //计算需要移动的距离
        const t = e.clientX - qrCodedisX.value;
        const y = e.clientY - qrCodedisY.value;
        //移动当前元素
        const el = document.getElementById("qrCodeId");
        if (t >= 0 && el && t <= window.innerWidth - el.offsetWidth) {
          el.style.left = t + "px";
          el.style.top = y + "px";
          el.style.bottom = "auto";
        }
      }
    };

    const qrCodeDown = (e: any) => {
      qrCodePostion.value = true;
      const el = document.getElementById("qrCodeId");
      qrCodedisX.value = e.clientX - (el ? el.offsetLeft : 0);
      qrCodedisY.value = e.clientY - (el ? el.offsetTop : 0);
      console.log("qrCodeDown:" + qrCodedisX.value);
      document.onmousemove = qrCodeMove;
      document.onmouseup = qrCodeup;
    };

    const qrCodeup = (e: any) => {
      qrCodePostion.value = false;
      document.onmousemove = null;
      document.onmouseup = null;
    };

    return {
      Programme,
      Barrage,
      showBarrage,
      showProgramme,
      showPrize,
      showQRCode,
      showGift,
      qrCodeUrl,
      programmeList,
      hallCode,
      prizeType,
      prizeInfo,
      isDrawing,
      giftGiver,
      resultList,
      stringList,
      mp4Url,
      qrCodeDown,
      superBarrage
    };
  }
});

/**
 * 连接WebSocket
 */
function useWebSocket(
  hallCode: string,
  Programme: any,
  Barrage: any,
  showBarrage: any,
  showProgramme: any,
  showPrize: any,
  showGift: any,
  showQRCode: any,
  giftGiver: any,
  stringList: any,
  mp4Url: any,
  _getNewsRecordAndGiftList: any,
  superBarrage: any
) {
  const ws: any = ref();
  const prizeType = ref();
  const prizeInfo = ref();
  const bgUrl = ref();
  const isDrawing = ref(false);
  const giftQueue: any = ref([]);
  const resultList: any = ref();
  const router = useRouter();
  const svgaList: any = {};
  const initDBRes: any = {};
  const db = new DB();

  watch(giftQueue.value, val => {
    console.log(val);
    if (val.length > 0 && !showGift.value) {
      giftGiver.value = giftQueue.value[0];
      showGift.value = true;
      setTimeout(() => {
        svgaAnimation();
      });
    }
  });

  onMounted(async () => {
    // setTimeout(() => {
    //   giftQueue.value.push({
    //     avatar: '',
    //     name: 'test',
    //     type: '跑车',
    //     code: 'rocket'
    //   })
    // }, 1000);
    _initWebSocket();
    // initDBRes = await initDB();
    // setTimeout(() => {
    //   _getEffectGiftList();
    // }, 8000);
    // giftQueue.value.push({
    //   avatar: 'https://fdshare.oss-cn-shenzhen.aliyuncs.com/meet/invitation/barrageList/%E7%9C%BC%E7%9C%89%E8%BF%B7%E4%BA%BA.png',
    //   name: 'liuhuang',
    //   type: '红包',
    //   code: 'luckymoney',
    //   svgaUrl: 'https://fdshare.oss-cn-shenzhen.aliyuncs.com/live/specialEffects/svga%E5%8C%85/luckymoney_web.svga'
    // });
  });

  const _initWebSocket = () => {
    ws.value = new reconnectingWebsocket(`${process.env.VUE_APP_Url_WebSocket}?bc=${hallCode}&ut=${hallCode}`); // 生产
    ws.value.onopen = _wsOnOpen;
    ws.value.onmessage = _wsOnMessage;
    ws.value.onerror = _wsOnError;
    ws.value.onclose = _wsOnClose;
  };

  const _wsOnOpen = () => {
    console.log("ws建立连接");
  };

  const _wsOnMessage = (msg: any) => {
    console.log("ws新消息", JSON.parse(msg.data));
    const {
      sendType,
      messageContent,
      sendUserName,
      sendUserHeadImgUrl
    } = JSON.parse(msg.data);
    const messageStr =
      sendType === "1" || sendType === "20" || sendType === "25"
        ? messageContent.messageStr
        : JSON.parse(messageContent.messageStr);

    if (sendType === "1" || sendType === "25") {
      // 弹幕
      Barrage.value.append(
        {
          text: messageStr,
          avatar: sendUserHeadImgUrl
        },
        true
      );
      Barrage.value.realTimeAppend({
        text: messageStr,
        avatar: sendUserHeadImgUrl
      });
    } else if (sendType === "3") {
      // 弹幕控制
      showBarrage.value = messageStr.isShow === "0" ? false : true;
    } else if (sendType === "28") {
      // 弹幕控制
      showBarrage.value = messageStr.isShow === "0" ? false : true;
      if (showBarrage.value) _getNewsRecordAndGiftList(1);
      if (!showBarrage.value) Barrage.value.append({}, showBarrage.value);
    } else if (sendType === "4") {
      // 节目表
      showProgramme.value = messageStr.isShow === "0" ? false : true;
    } else if (sendType === "5" || sendType === "27") {
      // 祝福墙礼物
      if (messageStr.effect === "1") {
        giftQueue.value.push({
          avatar: sendUserHeadImgUrl,
          name: sendUserName,
          type: messageStr.gift_name,
          code: messageStr.gift_code,
          svgaUrl: messageStr.gift_gif
        });
      } else {
        Barrage.value.append({
          text: `${sendUserName} 赠送了 ${messageStr.gift_name}`,
          avatar: sendUserHeadImgUrl,
          giftIcon: messageStr.gift_gif
        });

        Barrage.value.realTimeAppend({
          text: `${sendUserName} 赠送了 ${messageStr.gift_name}`,
          avatar: sendUserHeadImgUrl,
          giftIcon: messageStr.gift_gif
        });
      }
    } else if (sendType === "6") {
      // 抽奖
      showPrize.value = true;
      if (messageStr.type === "0") {
        prizeInfo.value = {
          name: messageStr.name,
          context: messageStr.context || ""
        };
        prizeType.value = 1;
      } else if (messageStr.type === "1") {
        prizeType.value = 2;
        isDrawing.value = true;
        const dom: any = document.getElementById("main");
        dom.style.background = "#000";
      }
    } else if (sendType === "7") {
      // 获奖人员
      getPrizesWinner({
        prizeId: messageStr.prizeId
      }).then((res: any) => {
        console.log(res);
        if (res.data.code === 101) {
          resultList.value = [];
          resultList.value = res.data.result;

          showPrize.value = true;
          isDrawing.value = false;
          const dom: any = document.getElementById("main");
          dom.style.backgroundImage = `url(${bgUrl.value})`;
          prizeType.value = 3;
        }
      });
    } else if (sendType === "8") {
      // 二维码控制
      showQRCode.value = messageStr.isShow === "0" ? false : true;
    } else if (sendType === "9") {
      stringList.value = [];
      mp4Url.value = "";
      const dom: any = document.getElementById("main");
      dom.style.backgroundImage = `url()`;
      if (messageStr.bdType === "1") {
        //1.互动背景 2.婚礼轮播 3.婚礼视频
        // 背景图片
        const url = messageStr.ossUrl;
        bgUrl.value = url;
        // const dom: any = document.getElementById("main");
        dom.style.backgroundImage = `url(${url})`;
      } else if (messageStr.bdType === "2") {
        stringList.value = messageStr.ossUrl;
      } else if (messageStr.bdType === "3") {
        mp4Url.value = messageStr.ossUrl;
      }
    } else if (sendType === "10") {
      // 赞或者踩
      Programme.value.getProgrammeList();
    } else if (sendType === "17") {
      // 关闭抽奖
      showPrize.value = false;
    } else if (sendType === "20") {
      // 系统消息
      // childeProgramme.value.getProgrammeList()
      Barrage.value.append(
        {
          text: messageStr,
          avatar: sendUserHeadImgUrl
        },
        true
      );
    } else if (sendType === "33") {
      // 红包
    } else if (sendType === "43") {
      router.push({
        path: `/CountingMoney`,
        query: {
          hallCode: hallCode
        }
      });
    } else if (sendType === "47") {
      router.push({
        path: `/`,
        query: {
          hallCode: hallCode
        }
      });
    } else if (sendType === "49") {
      router.push({
        path: `/CarRace`,
        query: {
          hallCode: hallCode
        }
      });
    } else if (sendType === "50") {
      router.push({
        path: `/`,
        query: {
          hallCode: hallCode
        }
      });
    } else if (sendType === "57") {
      superBarrage.value.superSvgaAnimation(msg.data);
    }
  };

  const _wsOnError = (e: any) => {
    console.log("ws发生错误", e);
  };

  const _wsOnClose = () => {
    console.log("ws断开连接");
  };

  /**
   * 礼物动画
   */
  const svgaAnimation = async () => {
    giftGiver.value = giftQueue.value[0];

    // const parser = new Parser()
    // const svga = await parser.load(isExistFile(svgaUrl) ? svgaUrl : giftQueue.value[0].svgaUrl)

    // const player = new Player({container: document.getElementById('canvas') as HTMLCanvasElement,
    //                           loop: 1,
    //                           isCacheFrames: true,
    //                           isOpenNoExecutionDelay: true
    //                     })
    // // this.$refs.input1
    // await player.mount(svga)

    let svga = await db.find(giftQueue.value[0].code);
    if (!svga) {
      let svgaUrl = `https://fdshare.oss-cn-shenzhen.aliyuncs.com/live/specialEffects/svga%E5%8C%85/${giftQueue.value[0].code}_web.svga`;
      svgaUrl = (await isExistFile(svgaUrl))
        ? svgaUrl
        : giftQueue.value[0].svgaUrl;
      const parser = new Parser({ isDisableImageBitmapShim: true });
      svga = await parser.load(svgaUrl);
      await db.insert(giftQueue.value[0].code, svga);
    }
    const player = new Player({
      container: document.getElementById("canvas") as HTMLCanvasElement,
      loop: 1,
      isOpenNoExecutionDelay: true
    });
    await player.mount(svga);

    player.onEnd = () => {
      console.log("onEnd");
      giftQueue.value.shift();
      if (giftQueue.value.length > 0) {
        svgaAnimation();
      } else {
        showGift.value = false;
      }
    };
    // 开始播放动画
    player.start();

    // const initDBRes = await initDB();
    // const getByGiftCodeRequest = initDBRes.objectStore.get(
    //   giftQueue.value[0].code
    // );
    // let startTime = new Date().getTime()

    // initDBRes.objectStore.get("url").onsuccess = () => {
    // console.log(giftQueue.value[0].code + " 1 load time : " + (new Date().getTime() - startTime))
    //   console.log(getByGiftCodeRequest.result);

    //   if (!getByGiftCodeRequest.result) {
    //     parser.load(
    //       isExistFile(svgaUrl) ? svgaUrl : giftQueue.value[0].svgaUrl,
    //       videoItem => {

    //         console.log(giftQueue.value[0].code + " b2 load time : " + (new Date().getTime() - startTime))
    //         // 新增
    //         initDBRes.objectStore.add({
    //           url: giftQueue.value[0].giftCode,
    //           result: videoItem
    //         });

    //         player.loops = 1;

    //         player.onFinished(() => {
    //           giftQueue.value.shift();
    //           if (giftQueue.value.length > 0) {
    //             svgaAnimation();
    //           } else {
    //             showGift.value = false;
    //           }
    //         });

    //         player.setVideoItem(videoItem);
    //         player.startAnimation();

    //         console.log(giftQueue.value[0].code + " b3 load time : " + (new Date().getTime() - startTime))
    //         setTimeout(function(){
    //            console.log(giftQueue.value[0].code + " b4 load time : " + (new Date().getTime() - startTime),1)
    //         })
    //       }
    //     );
    //   } else {
    //     player.loops = 1;

    //     player.onFinished(() => {
    //       giftQueue.value.shift();
    //       if (giftQueue.value.length > 0) {
    //         svgaAnimation();
    //         // console.log('svgaAnimation')
    //       } else {
    //         showGift.value = false;
    //       }
    //     });

    //     setTimeout(function(){
    //            console.log(giftQueue.value[0].code + " a5 load time : " + (new Date().getTime() - startTime))
    //         })
    //     console.log(giftQueue.value[0].code + " a2 load time : " + (new Date().getTime() - startTime))
    //     player.setVideoItem(getByGiftCodeRequest.result.result);
    //     console.log(giftQueue.value[0].code + " a3 load time : " + (new Date().getTime() - startTime))
    //     player.startAnimation();
    //     console.log(giftQueue.value[0].code + " a4 load time : " + (new Date().getTime() - startTime))

    //   }
    // };
  };

  /**
   * 判断服务的文件是否存在
   * @param url 文件地址
   * @returns {Boolean}
   */
  const isExistFile = (url: string) => {
    if (url == null) {
      return false;
    }
    let xmlhttp;
    if (window.XMLHttpRequest) {
      xmlhttp = new XMLHttpRequest();
    } else {
      xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
    }
    xmlhttp.open("GET", url, false);
    xmlhttp.send();
    if (xmlhttp.readyState === 4) {
      if (xmlhttp.status === 200) return true;
      //url存在
      else if (xmlhttp.status === 404) return false;
      //url不存在
      else return false; //其他状态
    }
  };

  // const _getEffectGiftList = async () => {
  //   getEffectGiftList({}).then(async (res: any) => {
  //     const { code, result } = res.data;
  //     if (code === 101) {
  //       // console.log(JSON.stringify(result))
  //       result.forEach((item: any) => {
  //         if (!svgaList[item.giftCode]) {
  //           const parser = new SVGA.Parser();
  //           const svgaUrl = `https://fdshare.oss-cn-shenzhen.aliyuncs.com/live/specialEffects/svga%E5%8C%85/${item.giftCode}_web.svga`;
  //           parser.load(
  //             isExistFile(svgaUrl) ? svgaUrl : item.giftGifImg,
  //             function(videoItem) {
  //               svgaList[item.giftCode] = videoItem;
  //             }
  //           );
  //         }
  //       });
  //     }
  //   });
  // };

  return {
    prizeType,
    prizeInfo,
    isDrawing,
    bgUrl,
    resultList
  };
}

/**
 * 获取浏览器参数
 */
function useQueryParams() {
  const search = window.location.href
    .replace(/^\s+/, "")
    .replace(/\s+$/, "")
    .match(/([^?#]*)(#.*)?$/); // 提取location.search中'?'后面的部分
  if (!search) {
    return {};
  }
  const searchStr = search[1];
  const searchHash = searchStr.split("&");

  const ret: any = {};
  for (let i = 0, len = searchHash.length; i < len; i++) {
    // 这里可以调用each方法
    let pair: any = searchHash[i];
    if ((pair = pair.split("="))[0]) {
      const key = decodeURIComponent(pair.shift());
      let value = pair.length > 1 ? pair.join("=") : pair[0];

      if (value != undefined) {
        value = decodeURIComponent(value);
      }
      if (key in ret) {
        if (ret[key].constructor != Array) {
          ret[key] = [ret[key]];
        }
        ret[key].push(value);
      } else {
        ret[key] = value;
      }
    }
  }
  return ret;
}
