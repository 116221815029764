
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  watch,
  nextTick
} from "vue";
import SVGA from "svgaplayerweb";
// eslint-disable-next-line @typescript-eslint/class-name-casing
interface listItem {
  frame: string;
  color: string;
  avatar: string;
  giftIcon: string;
  text: string;
}

export default defineComponent({
  name: "CrossCycleBarrage",
  setup() {
    const svgaAnimation = () => {
      const player = new SVGA.Player("#demoCanvas");
      player.setContentMode("AspectFill");
      const parser = new SVGA.Parser();
      parser.load(
        "https://fdshare.oss-cn-shenzhen.aliyuncs.com/meet/superNews/%E5%BC%80%E5%BF%83%E5%B0%B1%E5%A5%BD.svga",
        function(videoItem) {
          player.loops = 1;
          player.onFinished(() => {
            nextTick(() => {
              setTimeout(() => {
                svgaAnimation();
                player.setVideoItem(videoItem);
                player.startAnimation();
              }, 3000);
            });
          });
        }
      );
    };
    svgaAnimation();
    const allList = reactive<listItem[]>([]);
    const allIndex = ref(0);
    const newList = reactive<listItem[]>([]);
    const newIndex = ref(0);
    const currentList = reactive<listItem[]>([]);
    const currentIndex = ref(0);
    const timer = ref();
    const offsetHeight = ref(0);

    const a = ref(0);
    const item = ref(true);
    const rand = ref(0);
    const arr = ref<number[]>([]);
    const showBarrage = ref(false);

    watch(
      () => currentList.length,
      () => {
        if (!showBarrage.value) {
          return;
        }
        if (!arr.value.length) {
          arr.value = [0, 16, 32, 48, 64, 80];
        }
        const index = currentList.length - 1;
        let randoms = Math.random() * arr.value.length; //获取随机数
        if (rand.value === arr.value[Math.floor(randoms)]) {
          randoms = randoms + 1;
        }
        nextTick(() => {
          // if (rand.value === randoms && randoms !== 5) {
          //   randoms = randoms + 1;
          // } else if (rand.value === randoms && randoms === 5) {
          //   randoms = randoms - 1;
          // }
          rand.value = arr.value[Math.floor(randoms)];
          const itemIndex = arr.value[Math.floor(randoms)];
          // console.info(arr.value);
          const dom: any = document.getElementsByClassName("bounceIn_left")[
            index
          ];
          dom.style.top = `${itemIndex}vh`;
          arr.value.splice(Math.floor(randoms), 1);
          // if (item.value) {
          if (currentList.length > 50) {
            currentList.splice(0, 1);
          }
          // }
        });
        // const h = document.getElementsByTagName("li")[index].offsetHeight;
        // const dom: any = document.getElementsByClassName("barrage-group")[0];
        // dom.style.transform = `translateY(-${offsetHeight.value + h}px)`;
        // offsetHeight.value = offsetHeight.value + h;
        // }
      }
    );
    onMounted(() => {
      // const arr = [{
      //   name: '二除以三先生',
      //   text: '1'
      // }, {
      //   name: '二除以三先生',
      //   text: '2'
      // }, {
      //   name: '二除以三先生',
      //   text: '3'
      // }, {
      //   name: '二除以三先生',
      //   text: '4'
      // }, {
      //   name: '二除以三先生',
      //   text: '5'
      // }]

      // for (let i = 0; i < arr.length; i++) {
      //   allList.push(arr[i])
      // }

      // for (let i = 0; i < 4; i++) {
      //   if(allList[i]) currentList.push(allList[i])
      //   currentIndex.value++
      // }

      // setInterval(() => {
      //   newList.push({
      //     name: '二除以三先生',
      //     // text: 'n' + Math.floor(Math.random() * 10).toString()
      //     text: 'n' + a.value++
      //   })
      // }, 3000)

      openTimer();
    });
    const onClass = (value: any) => {
      if (value === "kaixin") {
        return "list_head_portrait1";
      } else if (value === "shili") {
        return "list_head_portrait";
      } else {
        return "list_head_portrait2";
      }
    };
    const onWidthClass = (value: any) => {
      if (value === "kaixin") {
        return "kaixin";
      } else if (value === "shili") {
        return "shili";
      } else {
        return "zhenai";
      }
    };
    const append = (e?: any, barrage?: any) => {
      showBarrage.value = barrage;
      if (!barrage) {
        currentList.length = 0;
        return;
      }
      // this.list.push('弹幕' + String(Math.random()).slice(2, 8))
      const n: any = Math.floor(Math.random() * 3);

      const obj: any = [
        {
          frame: `/img/head_frame_0.png`,
          color: "#55467c"
        },
        {
          frame: `/img/head_frame_1.png`,
          color: "#570e0a"
        },
        {
          frame: `/img/head_frame_2.png`,
          color: "#861332"
        }
      ];

      newList.push({
        frame: obj[n].frame,
        color: obj[n].color,
        text: decodeURIComponent(e.text),
        avatar: e.avatar,
        giftIcon: e.giftIcon || ""
      });

      // if (currentList.length >= 4) {
      //   const index = currentList.length - 4
      //   const h = document.getElementsByTagName('li')[index].offsetHeight
      //   //  indexstyle="`transform: translateY(-${(currentListh - 4) * 80}px)`"
      //   // console.log(offsetHeight.value)
      //   // console.log(offsetHeight.value + h + 20)
      //   const dom: any = document.getElementsByClassName('barrage-group')[0]
      //   dom.style.transform = `translateY(-${offsetHeight.value + h + 20}px)`
      //   offsetHeight.value = offsetHeight.value + h + 20
      //   currentList.push({
      //     frame: obj[n].frame,
      //     color: obj[n].color,
      //     text: e.text,
      //     avatar: e.avatar,
      //     giftIcon: e.giftIcon || ''
      //   })
      //   // currentList.shift()
      // } else {
      //   currentList.push({
      //     frame: obj[n].frame,
      //     color: obj[n].color,
      //     text: e.text,
      //     avatar: e.avatar,
      //     giftIcon: e.giftIcon || ''
      //   })
      // }

      // const vm = this
      // setTimeout(function() {
      //   vm.list.push({
      //     frame: obj[n].frame,
      //     color: obj[n].color
      //   })
      // }, 1000)
    };
    const realTimeAppend = (e?: any) => {
      // this.list.push('弹幕' + String(Math.random()).slice(2, 8))
      const n: any = Math.floor(Math.random() * 3);

      const obj: any = [
        {
          frame: `/img/head_frame_0.png`,
          color: "#55467c"
        },
        {
          frame: `/img/head_frame_1.png`,
          color: "#570e0a"
        },
        {
          frame: `/img/head_frame_2.png`,
          color: "#861332"
        }
      ];

      console.log("实时弹幕");

      currentList.push({
        frame: obj[n].frame,
        color: obj[n].color,
        text: decodeURIComponent(e.text),
        avatar: e.avatar,
        giftIcon: e.giftIcon || ""
      });
    };

    /**
     * 重新循环
     */
    const recycle = () => {
      clearInterval(timer.value);

      if (currentList.length > 4) {
        currentList.push(allList[allIndex.value]);
        allIndex.value++;
      }
      openTimer();
    };

    /**
     * 开启循环定时器
     */
    const openTimer = () => {
      timer.value = setInterval(() => {
        if (newIndex.value < newList.length) {
          currentList.push(newList[newIndex.value]);
          newIndex.value++;
        } else if (currentList.length > 4) {
          if (allIndex.value < allList.length) {
            currentList.push(allList[allIndex.value]);
            allIndex.value++;
          } else {
            for (let i = 0; i < newList.length; i++) {
              allList.push(newList[i]);
            }
            newList.length = 0;
            newIndex.value = 0;
            allIndex.value = 0;
            recycle();
          }
        }
      }, 3000);
    };

    return {
      currentList,
      append,
      onClass,
      onWidthClass,
      realTimeAppend
    };
  }
});
