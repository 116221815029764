import qs from "qs";
import { fetch } from "@/api/fetch";
import api from "@/api/url";

/**
 * 初始化屏幕
 */
export function initScreen(params: object) {
  return fetch({
    url: api.path + "/screen/initScreen",
    method: "post",
    data: params
  });
}

/**
 * 获取中奖人员
 * @param prizeId 奖项ID
 */
export function getPrizesWinner(params: object) {
  return fetch({
    url: api.path + "/screen/getPrizesWinner",
    method: "post",
    data: params
  });
}

/**
 * 开始游戏
 * hallCode  宴会厅编码
 * gameType  游戏类型 0.数钱 1.摇一摇
 * gameId 游戏Id
 */
export function startGame(params: object) {
  return fetch({
    url: api.path + "/game/startGame",
    method: "post",
    data: params
  });
}

/**
 * 获取数钱游戏最后一轮的结果
 * hallcode  宴会厅编码
 * gameType  游戏类型 0.数钱 1.摇一摇
 * gameId 游戏Id
 */
export function getLastCtt(params: object) {
  return fetch({
    url: api.path + "/game/getLastCtt",
    method: "post",
    data: params
  });
}

/**
 * 检查当前是否有开始的轮次
 * hallcode  宴会厅编码
 * gameType  游戏类型 0.数钱 1.摇一摇
 */
export function checkRounds(params: object) {
  return fetch({
    url: api.path + "/game/checkRounds",
    method: "post",
    data: params
  });
}

/**
 * 下一轮
 * hallcode  宴会厅编码
 * gameType  游戏类型 0.数钱 1.摇一摇
 */
export function nextRounds(params: object) {
  return fetch({
    url: api.path + "/game/nextRounds",
    method: "post",
    data: params
  });
}

/**
 * 查询霸屏礼物
 */
export function getEffectGiftList(params: object) {
  return fetch({
    url: api.path + "/screen/getEffectGiftList",
    method: "get",
    data: params
  });
}

/**
 * 首页二维码地址
 * uuid  uuid
 */
export function qrimageLogo(params: object) {
  return fetch({
    url: api.path + "/screen/qrimageLogo",
    method: "get",
    data: params
  });
}

// /**
//  * 获取二维码
//  */
// export function getQRCode(params: object) {
//   return fetch({
//     url: api.path + '/cloudStorage/qrimageLogo',
//     method: 'get',
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded'
//     },
//     params: params,
//     responseType: 'blob'
//   })
// }

// /**
//  * 获取宴会信息
//  * @param bantCode 宴会Code
//  */
// export function getBanquetInfo(params: object) {
//   return fetch({
//     url: api.path + '/banquet/getBantOne',
//     method: 'get',
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded'
//     },
//     params: params,
//   })
// }

// /**
//  * 获取节目单
//  * @param sType           类型
//  * @param sendToUserToken ID
//  */
// export function getGuides(params: object) {
//   return fetch({
//     url: api.path + '/throwingScreen/responseData',
//     method: 'get',
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded'
//     },
//     params: params
//   })
// }

// /**
//  * 接口1
//  * @param params1 // 参数1
//  * @param params2 // 参数2
//  */
// export function api1(params: object) {
//   return fetch({
//     url: api.path + '/api1',
//     method: 'post',
//     data: params
//   })
// }

// /**
//  * 接口2
//  * @param params1 // 参数1
//  * @param params2 // 参数2
//  */
// export function api2(params: object) {
//   return fetch({
//     url: api.path + '/api2',
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded'
//     },
//     data: qs.stringify(params)
//   })
// }

// /**
//  * 接口3
//  * @param params1 // 参数1
//  * @param params2 // 参数2
//  */
// export function api3(params: object) {
//   return fetch({
//     url: api.path + '/api3',
//     method: 'get',
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded'
//     },
//     params: params
//   })
// }

/**
 * 查询弹幕和礼物混合记录
 * hallcode  宴会厅编码
 * pageNum  消息分页，默认第一页 1
 * pageSize 每页条数，默认10条
 */
export function getNewsRecordAndGiftList(params: object) {
  return fetch({
    url: api.path + "/screen/getNewsRecordAndGiftList",
    method: "post",
    data: params
  });
}
