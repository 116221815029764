
import { defineComponent, ref, onMounted, watch } from "vue";
export default defineComponent({
  name: "Prize",
  props: {
    prizeType: {
      type: String,
      default: ""
    },
    resultList: {
      type: Array,
      defalute: []
    },
    resultList2: {
      type: Array,
      defalute: []
    },
    prizeInfo: {
      type: Object,
      defalute: {}
    }
  },
  setup(props) {
    const prizeName = ref();
    const prizeContent = ref();
    const prizeAnimation = ref();
    const timer = ref();

    onMounted(() => {
      // startAnimation()
    });

    watch(
      () => props.prizeType,
      (val: any) => {
        if (val === 2) {
          startAnimation();
        } else {
          clearInterval(timer.value);
        }
      }
    );

    /**
     * 抽奖动画
     */
    const startAnimation = () => {
      const list = ["钟浩南", "钟立", "龙秋云", "黄家伍"];
      timer.value = setInterval(() => {
        prizeAnimation.value = list[Math.floor(Math.random() * 3)];
      });
    };

    return {
      prizeName,
      prizeContent,
      prizeAnimation
    };
  }
});
