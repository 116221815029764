import axios from 'axios' //引入axios
// import vm from '@/main'

export function fetch(options: any) {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      //instance创建一个axios实例，可以自定义配置，可在 axios文档中查看详情
      //所有的请求都会带上这些配置，比如全局都要用的身份信息等。
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded'
        'Content-Type': 'application/json'
      },
      timeout: 30 * 1000 // 30秒超时
    })

    // 添加请求拦截器
    instance.interceptors.request.use(
      config => {
        if (window.localStorage.userToken) {
          config.headers.common['Authorization'] = window.localStorage.getItem(
            'userToken'
          )
        }
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    // 添加响应拦截器
    // instance.interceptors.response.use(function (response) {
    //   if (response.data.code === 'fail' || response.data.code === 'session-fail') {
    //     vm.$toast.fail('登录信息失效')
    //     vm.$router.replace('/login')
    //   }
    //   return response;
    // }, function (error) {
    //   vm.$toast.fail('网络错误')
    //   return Promise.reject(error);
    // });

    instance(options)
      .then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
  })
}
