const api = {
  // path: process.env.NODE_ENV === 'development' ? '/api' : 'https://api.51afd.com/fd-xinnuo'
  // path: process.env.NODE_ENV === 'development' ? '/api' : 'https://tme.xinnuoshijia.com' // 测试
  // path: process.env.NODE_ENV === 'development' ? '/api' : 'https://meetme.meetartscenter.com'  // 生产
  path:
    process.env.NODE_ENV === "production"
      ? process.env.VUE_APP_ProductionUrl
      : "/api" // 本地
};

export const webSocket = process.env.VUE_APP_Url_WebSocket;

export default api;
