
import { defineComponent, ref, watch, onMounted } from "vue";
import reconnectingWebsocket from "reconnecting-websocket";
import { startGame, getLastCtt, checkRounds, nextRounds } from "@/api/api"; // 接口
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "CarRace",
  setup() {
    const params = useQueryParams(); // 浏览器参数
    const { query } = useRoute();
    const ws: any = ref();
    const hallcode = ref();
    const gameCount = ref(0);
    const gameId = ref();
    const beforeCountdown = ref();
    const countdown = ref();
    const places = ref(10);
    const topTen = ref([]);
    const isRounds = ref(); // 0已结束，1未开始，2进行中，3初始状态
    const socketType = ref();
    const addUser: any = ref([]);
    const router = useRouter();
    const cttUser: any = ref([]);
    const leftN = ref(150);

    const qrCodePostion = ref();
    const qrCodedisX = ref();
    const qrCodedisY = ref();
    const qrCodeUrl = ref();
    const showQRCode: any = ref(true);

    onMounted(() => {
      hallcode.value = params.hallCode;
      if (!hallcode.value) {
        hallcode.value = query.hallCode;
      }
      if(document.body.clientWidth>960){
        leftN.value = 300
      }
      qrCodeUrl.value = query.qrCodeUrl
      showQRCode.value = query.showQRCode
      _checkRounds();
      _initWebSocket();
    });

    const _startGame = () => {
      startGame({
        hallcode: hallcode.value,
        gameType: "1",
        gameId: gameId.value,
      }).then((res: any) => {
        const { code, result } = res.data;
        if (code === 101) {
          isRounds.value = 2;
        } else {
        }
      });
    };

    const _checkRounds = () => {
      checkRounds({
        hallcode: hallcode.value,
        gameType: "1",
      }).then((res: any) => {
        const { code, result } = res.data;
        if (code === 101) {
          isRounds.value = result.isRounds;
          if (result.isRounds === 0) {
            _getLastCtt();
          } else if (result.isRounds === 2) {
            gameCount.value = result.count;
            gameId.value = result.gameId;
          } else if (result.isRounds === 1) {
            gameId.value = result.gameId;
            gameCount.value = result.count;
            addUser.value = [];
            for (const key in result.users) {
              addUser.value.push(result.users[key]);
            }
          } else if (result.isRounds === 3) {
            _nextRounds();
          }
        }
      });
    };

    const _getLastCtt = () => {
      getLastCtt({
        hallcode: hallcode.value,
        gameType: "1",
      }).then((res: any) => {
        const { code, result } = res.data;
        if (code === 101) {
          if (result.users) {
            if(result.users.length>10) {
              cttUser.value = result.users.slice(0,10);
            }else {
              cttUser.value = result.users;
            }
          }
        }
      });
    };

    const _nextRounds = () => {
      nextRounds({
        hallcode: hallcode.value,
        gameType: "1",
      }).then((res: any) => {
        const { code, result } = res.data;
        if (code === 101) {
          gameId.value = result;
          isRounds.value = 1;
          topTen.value = [];
          // addUser.value = []
          // gameCount.value = 0
          socketType.value = "";
        }
      });
    };

    const _initWebSocket = () => {
      ws.value = new reconnectingWebsocket(`${process.env.VUE_APP_Url_WebSocket}?bc=${hallcode.value}&ut=${hallcode.value}`); // 生产
      ws.value.onopen = _wsOnOpen;
      ws.value.onmessage = _wsOnMessage;
      ws.value.onerror = _wsOnError;
      ws.value.onclose = _wsOnClose;
    };

    const _wsOnOpen = () => {
      console.log("ws建立连接");
    };

    const _wsOnMessage = (msg: any) => {
      // console.log("ws新消息", JSON.parse(msg.data));
      console.log("ws新消息", msg.data);
      const { sendType, messageContent, sendUserName, sendUserHeadImgUrl } =
        JSON.parse(msg.data);
      const messageStr =
        sendType === "1" || sendType === "20" || sendType === "25"
          ? messageContent.messageStr
          : JSON.parse(messageContent.messageStr);
      if (sendType === "8") {
        // 二维码控制
        showQRCode.value = messageStr.isShow === "0" ? false : true;
      } else if (sendType === "39"){
        isRounds.value = 2;
      } else if (sendType === "50") {
        router.push({
          path: `/businessMain`,
          query: {
            hallCode: hallcode.value
          }
        });
      } else if (sendType === "51") {
        // 游戏参与人数
        if (hallcode.value === messageStr.hallCode) {
          gameCount.value = messageStr.count;
          if (messageStr.addUser) {
            let isOK = false;
            for (let i = 0; i < addUser.value.length; i++) {
              if (addUser.value[i].openId === messageStr.addUser.openId) {
                isOK = true;
              }
            }
            if (!isOK) {
              addUser.value.push(messageStr.addUser);
            }
          } else if (messageStr.removeUser) {
            for (let i = 0; i < addUser.value.length; i++) {
              if (addUser.value[i].openId === messageStr.removeUser.openId) {
                addUser.value.splice(i, 1);
              }
            }
          }
        }
      } else if (sendType === "52") {
        isRounds.value = 2;
        socketType.value = sendType;
        if (gameId.value === messageStr.gameId) {
          if (messageStr.djs === 0) {
            beforeCountdown.value = "GO";
          } else {
            beforeCountdown.value = messageStr.djs;
          }
        }
      } else if (sendType === "53") {
        socketType.value = sendType;
        if (gameId.value === messageStr.gameId) {
          if (messageStr.djs === -1) {
            isRounds.value = 0;
            _getLastCtt();
          }
          countdown.value = messageStr.djs === -1 ? 0 : messageStr.djs;
          topTen.value = messageStr.users;
        }
      } else if (sendType === "54") {
        gameId.value = messageStr.gameId
        isRounds.value = 1
        topTen.value = []
        socketType.value = ''
      }
    };

    const _wsOnError = (e: any) => {
      console.log("ws发生错误", e);
    };

    const _wsOnClose = () => {
      console.log("ws断开连接");
    };

    const routerNavigateTo = (url: string, param: any) => {
      router.push({
        path: url,
        query: param,
      });
    };

    const qrCodeMove = (e: any) => {
      if (qrCodePostion.value) {
        console.log(e);
        //计算需要移动的距离
        const t = e.clientX - qrCodedisX.value;
        const y = e.clientY - qrCodedisY.value;
        //移动当前元素
        const el = document.getElementById("qrCodeId");
        if (t >= 0 && el && t <= window.innerWidth - el.offsetWidth) {
          el.style.left = t + "px";
          el.style.top = y + "px";
          el.style.bottom = "auto";
        }
      }
    };

    const qrCodeup = (e: any) => {
      qrCodePostion.value = false;
      document.onmousemove = null;
      document.onmouseup = null;
    };
    
    const qrCodeDown = (e: any) => {
      qrCodePostion.value = true;
      const el = document.getElementById("qrCodeId");
      qrCodedisX.value = e.clientX - (el ? el.offsetLeft : 0);
      qrCodedisY.value = e.clientY - (el ? el.offsetTop : 0);
      console.log("qrCodeDown:" + qrCodedisX.value);
      document.onmousemove = qrCodeMove;
      document.onmouseup = qrCodeup;
    };

    return {
      qrCodeUrl,
      _nextRounds,
      routerNavigateTo,
      gameCount,
      _startGame,
      beforeCountdown,
      countdown,
      places,
      topTen,
      socketType,
      isRounds,
      addUser,
      hallcode,
      cttUser,
      leftN,
      qrCodeDown,
      showQRCode
    };
  },
});

/**
 * 获取浏览器参数
 */
function useQueryParams() {
  const search = window.location.href
    .replace(/^\s+/, "")
    .replace(/\s+$/, "")
    .match(/([^?#]*)(#.*)?$/); // 提取location.search中'?'后面的部分
  if (!search) {
    return {};
  }
  const searchStr = search[1];
  const searchHash = searchStr.split("&");

  const ret: any = {};
  for (let i = 0, len = searchHash.length; i < len; i++) {
    // 这里可以调用each方法
    let pair: any = searchHash[i];
    if ((pair = pair.split("="))[0]) {
      const key = decodeURIComponent(pair.shift());
      let value = pair.length > 1 ? pair.join("=") : pair[0];

      if (value != undefined) {
        value = decodeURIComponent(value);
      }
      if (key in ret) {
        if (ret[key].constructor != Array) {
          ret[key] = [ret[key]];
        }
        ret[key].push(value);
      } else {
        ret[key] = value;
      }
    }
  }
  return ret;
}
